.sendbird-channel-settings__operator .sendbird-channel-settings__accordion-icon {
  position: absolute;
  left: 14px;
}
.sendbird-channel-settings__operator .sendbird-accordion__panel-heder {
  position: relative;
}

.sendbird-more-members__popup-scroll {
  max-height: 420px;
  overflow: scroll;
}
.sendbird-more-members__popup-scroll .sendbird-user-list-item__operator {
  right: 72px;
}
.sendbird-more-members__popup-scroll .sendbird-user-list-item__title {
  max-width: 240px;
}

.sendbird-channel-settings__empty-list {
  text-align: center;
  margin-top: 12px;
  margin-bottom: 4px;
  display: block;
}

.sendbird-channel-settings-accordion__footer {
  padding-top: 16px;
  display: flex;
  justify-content: left;
  padding-left: 12px;
}
.sendbird-channel-settings-accordion__footer .sendbird-button:nth-child(2) {
  margin-left: 8px;
}

.sendbird-channel-settings__freeze {
  max-width: 320px;
  position: relative;
  padding: 12px 22px 10px 56px;
}
.sendbird-theme--light .sendbird-channel-settings__freeze {
  background-color: var(--sendbird-light-background-50);
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-channel-settings__freeze {
  background-color: var(--sendbird-dark-background-600);
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}

.sendbird-channel-settings__frozen-icon {
  position: absolute;
  right: 20px;
  top: 12px;
}
.sendbird-channel-settings__frozen-icon .sendbird-icon {
  cursor: pointer;
}

.sendbird-channel-settings__members-list .sendbird-badge,
.sendbird-channel-settings__members .sendbird-badge {
  position: absolute;
  right: 44px;
  top: 12px;
}
.sendbird-theme--light .sendbird-channel-settings__members-list .sendbird-badge,
.sendbird-theme--light .sendbird-channel-settings__members .sendbird-badge {
  background-color: var(--sendbird-light-background-200);
}
.sendbird-theme--dark .sendbird-channel-settings__members-list .sendbird-badge,
.sendbird-theme--dark .sendbird-channel-settings__members .sendbird-badge {
  background-color: var(--sendbird-dark-background-400);
}
.sendbird-theme--light .sendbird-channel-settings__members-list .sendbird-badge .sendbird-label,
.sendbird-theme--light .sendbird-channel-settings__members .sendbird-badge .sendbird-label {
  color: var(--sendbird-light-onlight-02);
}
.sendbird-theme--dark .sendbird-channel-settings__members-list .sendbird-badge .sendbird-label,
.sendbird-theme--dark .sendbird-channel-settings__members .sendbird-badge .sendbird-label {
  color: var(--sendbird-dark-ondark-02);
}