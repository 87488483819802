.sendbird-openchannel-conversation-header {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
}
.sendbird-theme--light .sendbird-openchannel-conversation-header {
  border-bottom: 1px solid var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-openchannel-conversation-header {
  border-bottom: 1px solid var(--sendbird-dark-ondark-04);
}
.sendbird-openchannel-conversation-header .sendbird-openchannel-conversation-header__left__title {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 120px);
}
.sendbird-openchannel-conversation-header__left [class*=sendbird-openchannel-conversation-header__left__cover-image] {
  position: absolute;
  left: 20px;
  top: 16px;
}
.sendbird-openchannel-conversation-header__left__title {
  position: absolute;
  left: 60px;
  top: 12px;
}
.sendbird-openchannel-conversation-header__left__sub-title {
  position: absolute;
  left: 60px;
  top: 36px;
}
.sendbird-openchannel-conversation-header__right {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 64px;
  height: 64px;
}
.sendbird-openchannel-conversation-header__right__trigger {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendbird-openchannel-conversation-header__left__cover-image--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.sendbird-theme--light .sendbird-openchannel-conversation-header__left__cover-image--icon {
  background-color: var(--sendbird-light-background-300);
}
.sendbird-theme--dark .sendbird-openchannel-conversation-header__left__cover-image--icon {
  background-color: var(--sendbird-dark-background-300);
}