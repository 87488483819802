.sendbird-conversation__messages .sendbird-conversation__messages-padding {
  padding-left: 24px;
  padding-right: 24px;
  height: 100%;
  overflow: scroll;
}
.sendbird-conversation__messages .sendbird-separator,
.sendbird-conversation__messages .sendbird-admin-message {
  padding-top: 8px;
  padding-bottom: 8px;
}

.sendbird-conversation__scroll-bottom-button {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 30px;
  left: calc(100% - 65px);
  width: 40px;
  height: 40px;
  border-radius: 24px;
  z-index: 1;
}
.sendbird-theme--light .sendbird-conversation__scroll-bottom-button {
  background-color: var(--sendbird-light-background-50);
  box-shadow: var(--sendbird-light-shadow-05);
}
.sendbird-theme--dark .sendbird-conversation__scroll-bottom-button {
  background-color: var(--sendbird-dark-background-400);
  box-shadow: var(--sendbird-dark-shadow-05);
}
.sendbird-conversation__scroll-bottom-button:hover {
  cursor: pointer;
}
.sendbird-theme--light .sendbird-conversation__scroll-bottom-button:hover {
  background-color: var(--sendbird-light-background-100);
}
.sendbird-theme--dark .sendbird-conversation__scroll-bottom-button:hover {
  background-color: var(--sendbird-dark-background-500);
}
.sendbird-theme--light .sendbird-conversation__scroll-bottom-button:active {
  background-color: var(--sendbird-light-background-200);
}
.sendbird-theme--dark .sendbird-conversation__scroll-bottom-button:active {
  background-color: var(--sendbird-dark-background-700);
}
.sendbird-conversation__scroll-bottom-button:focus {
  outline: none;
}